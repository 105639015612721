import React from 'react';
import Featured from '../featured/featured';
import Hero from '../hero/hero';
import Messaging from '../messaging/messaging';

interface Components { 
  SanityFeatured: React.FC;
  SanityHero: React.FC;
  SanityMessaging: React.FC;
}

const Components: Components = {
  SanityFeatured: Featured,
  SanityHero: Hero,
  SanityMessaging: Messaging,
};

interface DataProps {
  components: {
    id: string;
    __typename: keyof Components;
  }[];
};

const Dynamic: React.FC<DataProps> = (props) => {
  return <React.Fragment>
    {
      props.components.map((component) => {
        return React.createElement(Components[component.__typename], {
          key: component.id,
          ...component
        });
      })
    }
  </React.Fragment>
};

export default Dynamic
