// If you don't want to use TypeScript you can delete this file!
import Img from 'gatsby-image';
import React from 'react';
import { IImage } from '../../contracts/images/iimage.interface';
import './hero.scss';

type DataProps = {
  image: IImage;
  shading: boolean;
  anchor: {
    current: string;
  };
};

const Hero: React.FC<DataProps> = (data) => (
  <div className="hero" id={data.anchor?.current}>
    <Img className="hero-image" fluid={data.image.asset.fluid} />
    {
      data.shading &&
        <div className="hero-shade"></div>
    }
  </div>
);

export default Hero
