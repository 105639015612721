import React from 'react';
import { IButton } from '../../contracts/buttons/ibutton';
import './messaging.scss';

type DataProps = {
  heading: string;
  content: string;
  button: IButton;
  backgroundColor: string;
  anchor: {
    current: string;
  };
};

const Messaging: React.FC<DataProps> = (data) => (
  <div className={'messaging' + data.backgroundColor ? (' bg-'+data.backgroundColor) : null} id={data.anchor?.current}>
    <div className="messaging-wrapper">
      <h2 className="messaging-heading">{ data.heading }</h2>
      <p className="messaging-content">{ data.content }</p>
      {
        (data.button && data.button.buttonText && data.button.buttonUrl) &&
          <a href={ data.button.buttonUrl } className={'btn btn-'+ data.button.buttonColor +' messaging-btn'}>{ data.button.buttonText }</a>
      }
    </div>
  </div>
);

export default Messaging
