import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Dynamic from '../components/dynamic/dynamic';
import Layout from '../components/layout';
import SEO from '../components/seo';

interface IHomeQuery {
  allSanityHome: {
    nodes: {
      id: string;
      components;
      seo: {
        seoTitle: string;
        seoDescription: string;
      };
    }[];
  };
}

const IndexPage = () => {
  const query: IHomeQuery = useStaticQuery(
    graphql`
      query {
        allSanityHome {
          nodes {
            id
            seo {
              seoDescription
              seoTitle
            }
            components {
              ... on SanityFeatured {
                id
                anchor {
                  current
                }
                layout
                heading
                content
                button {
                  buttonColor
                  buttonText
                  buttonUrl
                }
                altText
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityHero {
                id
                anchor {
                  current
                }
                shading
                altText
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityMessaging {
                id
                anchor {
                  current
                }
                heading
                content
                button {
                  buttonColor
                  buttonText
                  buttonUrl
                }
                backgroundColor
              }
            }
          }
        }
      }
    `
  )
  const homeData = query.allSanityHome.nodes[0];
  return <Layout>
    <SEO title={homeData.seo.seoTitle} description={homeData.seo.seoDescription} />
    <Dynamic components={homeData.components}></Dynamic>
  </Layout>
}

export default IndexPage