import Img from 'gatsby-image';
import React from 'react';
import { IButton } from '../../contracts/buttons/ibutton';
import { IImage } from '../../contracts/images/iimage.interface';
import './featured.scss';

type DataProps = {
  layout: 'normal' | 'reversed';
  heading: string;
  content: string;
  button: IButton;
  image: IImage;
  altText: string;
  anchor: {
    current: string;
  };
};

const Featured: React.FC<DataProps> = (data) => (
  <div className={'featured' + (data.layout === 'reversed' ? ' reversed' : '')} id={data.anchor?.current}>
    <div className="featured-content-container">
      <h2 className="featured-heading">{ data.heading }</h2>
      <p className="featured-content">{ data.content }</p>
      {
        data.button &&
          <div>
            <a href={ data.button.buttonUrl } className={'btn btn-'+ data.button.buttonColor +' featured-btn'}>{ data.button.buttonText }</a>
          </div>
      }
    </div>
    <div className="featured-image">
      <Img fluid={data.image.asset.fluid} />
    </div>
  </div>
);

export default Featured
